import React from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

const Rightbar = () => {
  return (
    <Box
      sx={{
        display: { xs: "none", sm: "block" },
      }}
      flex={2}
      p={2}
    >
      <Box position="fixed" width={400}>
        <Typography variant="h6" fontWeight={300}>
          Online friends
        </Typography>
        <AvatarGroup>
          <AvatarGroup max={4}>
            <Avatar alt="Remy Sharp" src="/images/profile.jpeg" />
            <Avatar alt="Travis Howard" src="/images/profile.jpeg" />
            <Avatar alt="Cindy Baker" src="/images/profile.jpeg" />
            <Avatar alt="Agnes Walker" src="/images/profile.jpeg" />
            <Avatar alt="Trevor Henderson" src="/images/profile.jpeg" />
          </AvatarGroup>
        </AvatarGroup>

        <Typography variant="h6" fontWeight={300} mt={2} mb={2}>
          Latest Photos
        </Typography>

        <ImageList cols={3} rowHeight={100} gap={5}>
          <ImageListItem>
            <img
              src="/images/profile_100.jpeg"
              srcSet="/images/profile_100.jpeg"
              alt="Brian May"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src="/images/profile_100.jpeg"
              srcSet="/images/profile_100.jpeg"
              alt="Brian May"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src="/images/profile_100.jpeg"
              srcSet="/images/profile_100.jpeg"
              alt="Brian May"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src="/images/profile_100.jpeg"
              srcSet="/images/profile_100.jpeg"
              alt="Brian May"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src="/images/profile_100.jpeg"
              srcSet="/images/profile_100.jpeg"
              alt="Brian May"
            />
          </ImageListItem>
          <ImageListItem>
            <img
              src="/images/profile_100.jpeg"
              srcSet="/images/profile_100.jpeg"
              alt="Brian May"
            />
          </ImageListItem>
        </ImageList>

        <Typography variant="h6" fontWeight={300} mt={2}>
          Latest Conversations
        </Typography>

        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Brunch this weekend?"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Ali Connors
                  </Typography>
                  {" — I'll be in your neighborhood doing errands this…"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Summer BBQ"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    to Scott, Alex, Jennifer
                  </Typography>
                  {" — Wish I could come, but I'm out of town this…"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Oui Oui"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Sandra Adams
                  </Typography>
                  {" — Do you have Paris recommendations? Have you ever…"}
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Rightbar;
